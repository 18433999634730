app-footer {
  margin-top: auto;
}

.layout-footer {
  padding: 0.6rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--surface-300);
}
