.layout-content-wrapper {
    transition: margin-left var(--transition-duration);
    height: 100vh;
    display: flex;
    flex-direction: column;

    .layout-content {
        overflow: auto;
        height: 100%;
        padding: 2rem;
        background-color: var(--surface-ground);
    }
}
